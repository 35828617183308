import { memo, useEffect, useRef } from "react";

import { useAbortController } from "use-abort-controller-hook";

import { StreamFileAttachment } from "@utility-types";

import { useSnackbar } from "providers/SnackbarProvider";
import useProgressStore from "store/useProgressStore";
import getFormattedFileSizeString from "utils/getFormattedFileSizeString";
import { isNativeMobile } from "utils/platform";
import shareFileNativeMobile from "utils/shareFileNativeMobile";

import FilePreview, {
  FilePreviewRef,
} from "components/FilePreview/FilePreview";
import ProgressModal from "components/Modals/ProgressModal";
import { Icon } from "components/design-system/icons";
import { FileIcon } from "components/helper";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

type Props = {
  files: StreamFileAttachment[];
};

const File = ({ files }: Props) => {
  const filePreviewRef = useRef<FilePreviewRef>(null);
  const firstRenderRef = useRef(true);
  const abortController = useAbortController();
  const { openSnackbar } = useSnackbar();

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const showProgress = () =>
    openModal(
      <ProgressModal
        autoCloseDelay={0}
        header={"Downloading File"}
        onCancel={abortController.abort}
      />
    );

  useEffect(() => {
    if (!firstRenderRef.current) return;
    firstRenderRef.current = false;
    const firstRender = firstRenderRef.current;

    return () => {
      !firstRender && abortController.abort();
    };
  }, [abortController]);

  return (
    <div className="file-attachment overflow max-w-full">
      <FilePreview
        files={files
          .filter(file => file.previewable)
          .map(file => ({
            id: file.file_id,
            title: file.title,
            url: file.asset_url,
          }))}
        ref={filePreviewRef}
      />
      <div className="flex flex-wrap">
        {files.map(
          ({
            asset_url: assetUrl,
            file_id: fileId,
            file_size: fileSize,
            mime_type: mimeType,
            previewable,
            title,
          }) => (
            <div
              key={fileId}
              className={tw(
                "flex items-center max-w-full overflow-hidden rounded-lg",
                "border border-border-container hover:border-border-container-hover h-56 my-6 mr-8",
                previewable ? " cursor-pointer" : " cursor-default"
              )}
              data-testid="attachment-file"
              onClick={async () => {
                if (!previewable) {
                  openSnackbar("error", "Sorry, file isn't previewable.", 2000);
                  return;
                }
                filePreviewRef.current?.openPreview({
                  id: fileId,
                  title,
                  url: assetUrl,
                });
              }}
            >
              <div className="flex items-center ml-8">
                <FileIcon mimeType={mimeType} iconSize={36} />
              </div>

              <div className="flex items-center justify-between grow min-w-0 max-w-full overflow-hidden px-12">
                <span className="overflow-hidden max-w-full">
                  <div className="text-body-bold text-text-primary truncate">
                    {title}
                  </div>
                  <div className="text-footnote text-text-subtle">
                    {getFormattedFileSizeString(fileSize)}
                  </div>
                </span>
                <div className="action-buttons hidden shrink-0 pl-8">
                  <a
                    className="hover:text-interactive-subtle-hover p-5 text-interactive-subtle"
                    href={assetUrl}
                    onClick={e => {
                      e.stopPropagation();

                      isNativeMobile() && e.preventDefault();

                      shareFileNativeMobile({
                        abortSignal: abortController.signal,
                        dialogTitle: "Share file",
                        fileName: title,
                        onError: () => {
                          useProgressStore.setState({
                            message: {
                              text: "Unknown error while sharing file.",
                              type: "error",
                            },
                          });
                        },
                        showProgress,
                        title: title,
                        url: assetUrl,
                      });
                    }}
                  >
                    <Icon icon="Download" size={22} />
                  </a>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default memo(
  File,
  (prev, next) =>
    prev.files.map(({ file_id }) => file_id).toString() ===
    next.files.map(({ file_id }) => file_id).toString()
);
