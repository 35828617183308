import { memo } from "react";

import { FileUploadState } from "@utility-types";
import { Button } from "components/design-system/Button";
import getFormattedFileSizeString from "utils/getFormattedFileSizeString";
import tw from "utils/tw";

import { FileIcon } from "components/helper";

import { Icon } from "components/design-system/icons";
import { LoadingIndicator } from ".";

type Props = {
  handleRetry: (id: string) => void;
  id: string;
  loadingState: FileUploadState;
  name: string;
  size: number;
  type: string;
  url?: string;
};

const FileAttachment = memo(
  ({
    handleRetry,
    id,
    loadingState,
    name,
    size,
    type,
    url,
  }: Props): JSX.Element => (
    <div
      className={tw(
        "relative overflow-hidden border rounded-lg h-56",
        loadingState === "failed"
          ? "border-accent-error"
          : "border-border-container hover:border-border-container-hover"
      )}
    >
      {loadingState === "uploading" && <LoadingIndicator />}
      <div className="flex items-center px-8 h-full min-w-[156px] max-w-[268px]">
        <span data-testid="file-icon">
          <FileIcon filename={name} mimeType={type} iconSize={36} />
        </span>
        <div className="ml-10" style={{ maxWidth: "calc(100% - 30px)" }}>
          {loadingState === "failed" ? (
            <>
              {name}
              <Button
                buttonStyle="none"
                buttonType="none"
                className="absolute justify-center w-full h-full bg-accent-error/50 rounded-md top-0 left-0 flex items-center"
                data-testid="uploading-file-failed"
                onClick={() => handleRetry(id)}
              >
                <Icon className="text-background" icon="RefreshCCW" />
              </Button>
            </>
          ) : (
            <a
              className="block line-clamp-1 truncate text-body-bold text-text-strong"
              href={url}
              download
              {...(loadingState !== "uploading"
                ? { "data-testid": "file-uploaded" }
                : {})}
            >
              {name}
            </a>
          )}

          {loadingState !== "failed" && (
            <div className="block truncate text-footnote text-text-subtle">
              {getFormattedFileSizeString(size)}
            </div>
          )}
        </div>
      </div>
    </div>
  ),
  (prev, next) =>
    prev.loadingState === next.loadingState &&
    prev.name === next.name &&
    prev.size === next.size
);

export default FileAttachment;
