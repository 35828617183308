import { format, startOfMonth, startOfWeek } from "date-fns";

export const formatRelativeDate = (dateProp: Date | string) => {
  const date = typeof dateProp === "string" ? new Date(dateProp) : dateProp;

  const startOfWeekDate = startOfWeek(new Date()).valueOf();

  if (date.valueOf() > startOfWeekDate) {
    return "This week";
  }

  const startOfLastWeekDate = startOfWeekDate - 1000 * 60 * 60 * 24 * 7;
  if (date.valueOf() > startOfLastWeekDate) {
    return "Last week";
  }

  const startOfMonthDate = startOfMonth(new Date()).valueOf();
  if (date.valueOf() > startOfMonthDate) {
    return "Last month";
  }

  return `${format(date, "LLLL")} ${format(date, "yyyy")}`;
};
